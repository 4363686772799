import React, { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import FileBase64 from 'react-file-base64';
import ContactEmployment from '../pages/ContactEmployment';
import ContactForm from '../components/ContactForm';
const Employment = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [age, setAge] = useState('');
  const [experience, setExperience] = useState('');
  const [files, setFiles] = useState([]);
  const [position, setPosition] = useState('');

  const [success, setSuccess] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [thisRecaptcha, setThisRecaptcha] = useState({});
  const [clicked2, setClicked2] = useState('show');
  const [error, setError] = useState('');
  const [thisResponse, setThisResponse] = useState({});
  const [fill, setFill] = useState(false);
  const [clicked3, setClicked3] = useState(false);
  const [fileMessage, setFileMessage] = useState('');
  async function sendFormWithFile() {
    const response = await fetch(
      'https://us-central1-test-9ecfd.cloudfunctions.net/employment',
      {
        method: 'POST',
        body: JSON.stringify({
          File:
            files.length > 0
              ? files[0].base64.replace('data:application/pdf;base64,', '')
              : 'No File',

          Details: {
            position: position,
            name: name,
            email: email,
            phone: phone,
            age: age,
            experience: experience,
            captcha: thisRecaptcha
          }
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        // console.log(clicked2);
        {
          data.test === 'captcha passed'
            ? setClicked2('success')
            : setError(data.message);
        }

        setThisResponse(data);
      })
      .catch(error => {
        setClicked2('error');
        setError(error.message);
        //   console.log(error.message);
      });
    // const json = await response.json();
  }
  const Contact = {
    Details: {
      name: name,
      email: email,
      phone: phone,
      age: age,
      experience: experience,
      captcha: thisRecaptcha
    }
  };
  function clickOnContact() {
    setClicked3(true);
    name && email && phone && age && experience
      ? sendContact(Contact)
      : setFill(true);
  }

  async function sendContact(Contact) {
    setClicked2('loading');
    if (files.length > 0) {
      if (files[0].type === 'application/pdf') {
        sendFormWithFile();
      }
    }
  }
  function onChange(value) {
    setThisRecaptcha(value);
  }
  useEffect(() => {
    if (files.length > 0) {
      setFileMessage('File Uploaded');
      if (files[0].type === 'application/pdf') {
        setFileMessage('File Uploaded');
      } else {
        setFileMessage('Please Upload a PDF File');
      }
    }
  }, [files]);

  return (
    <>
      <div id='employmentScreen'>
        <div id='employmentForm'>
          <Helmet>
            <title>Employment | Geoff Reynolds Roofing</title>
            <meta
              name='description'
              content='Let us now if you are interested in working with us. We are always looking for new talent to join our team.'
            />
          </Helmet>
          <ContactForm />
          {/* <p id='employmentInfo'>
            We're looking for experienced tradesmen with experience in metal
            roofing and cladding. If you are interested in working with us,
            please leave your details below and we'll be in touch soon.
          </p> */}
          <br></br>
          {fill && !name && !email && !phone && !age && !experience ? (
            <p id='error'>Please fill in all fields</p>
          ) : null}
          {thisRecaptcha === '' && clicked3 ? (
            <p id='error'>Please complete the reCAPTCHA</p>
          ) : null}
          {clicked2 == 'error' ? (
            <p id='success'>ERROR...please report this issue.</p>
          ) : null}
          {clicked2 == 'loading' ? <p id='success'>Loading...</p> : null}
          {clicked2 == 'show' && position != '' ? (
            <>
              <div id='inputItem'>
                <label>Name</label>
                <input
                  type='text'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder='Your Full Name'
                />
              </div>

              <div id='inputItem'>
                <label>Age</label>
                <input
                  type='text'
                  value={age}
                  onChange={e => setAge(e.target.value)}
                  placeholder='Your Age'
                />
              </div>

              <div id='inputItem'>
                <label>Experience</label>
                <textarea
                  type='text'
                  value={experience}
                  onChange={e => setExperience(e.target.value)}
                  placeholder='List below your roofing experience, or any qualifications'
                />
              </div>
              <div id='inputItem'>
                <label>Email</label>
                <input
                  type='text'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder='Your Email Address'
                />
              </div>
              <div id='inputItem'>
                <label>Phone</label>
                <input
                  type='text'
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  placeholder='Your Phone Number'
                />
              </div>
              {position == 'supervisor' ? (
                <div id='attachresume'>
                  Attach your resume below (PDF)
                  <br></br>
                  {fileMessage != '' ? <h4>{fileMessage}</h4> : null}
                  <FileBase64
                    multiple={true}
                    accept='.pdf'
                    onDone={setFiles.bind(this)}
                  />
                </div>
              ) : (
                <></>
              )}
              <div>
                <br></br>

                <ReCAPTCHA
                  sitekey='6LeweQAgAAAAAATVEucjTpWSuFWGQlc3O5EEKkG_'
                  onChange={onChange}
                />
              </div>
              <br></br>
              <button id='submitButton' onClick={clickOnContact}>
                Submit
              </button>
            </>
          ) : clicked3 === false ? (
            <>Please select a position above.</>
          ) : (
            <></>
          )}
        </div>

        {clicked2 == 'success' ? (
          <div id='succesDiv'>
            <p id='success'>
              We've got your details, someone from the office will be in touch
              with you soon.
            </p>
          </div>
        ) : null}

        {success && (
          <div>
            <p>Name: {name}</p>
            <p>Email: {email}</p>
            <p>Age: {age}</p>
            <p>Phone: {phone}</p>
          </div>
        )}
      </div>

      <ContactEmployment />
      {/* Footer */}
      {/* <Footer /> */}

      {/* Mobile Menu */}
      <MobileMenu />
    </>
  );
};

export default Employment;
// 6LeweQAgAAAAAATVEucjTpWSuFWGQlc3O5EEKkG_
