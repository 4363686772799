import React, {Component} from 'react';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {NavLink} from 'react-router-dom';
import { Helmet } from "react-helmet";

import PhotoGallery3 from './components/PhotoGallery3';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
      dataLayer: {
          userId: '001',
          userProject: 'project',
          page: 'project-fairy-meadow'
      },
      dataLayerName: 'architectural'
  };
class Projects extends Component{

    render(){
      TagManager.dataLayer(tagManagerArgs);
        return(
            <div>
                {/* Navigation bar */}

                <Helmet>
                  <title>Project: Fairy Meadow - Geoff Reynolds Roofing</title>
                  <meta name="description" content="Geoff Reynolds Roofing Pty Ltd provides house cladding installation services in the Wollongong area. Visit our website to learn how we can serve you." />

              <meta itemprop="name" content="Architectural Cladding Installation in Wollongong" />
              <meta itemprop="description" content="Geoff Reynolds Roofing Pty Ltd provides house cladding installation services in the Wollongong area. Visit our website to learn how we can serve you." />
              <meta itemprop="image" content="https://www.geoffreynolds.com.au/assets/img/projects/bulli3.jpg" /><meta name="keywords" content="metal roofing, house cladding, architectural cladding, wollongong"/>

              <meta name="twitter:card" content="https://www.geoffreynolds.com.au/assets/img/projects/bulli3.jpg" />
              <meta name="twitter:title" content="Architectural Cladding Installation in Wollongong" />
              <meta name="twitter:description" content="Geoff Reynolds Roofing Pty Ltd provides house cladding installation services in the Wollongong area. Visit our website to learn how we can serve you." />
              <meta name="twitter:image:src" content="https://www.geoffreynolds.com.au/assets/img/projects/bulli3.jpg" />

              <meta property="og:title" content="Architectural Cladding Installation in Wollongong" />
              <meta property="og:type" content="Site" />
              <meta property="og:url" content="https://www.geoffreynolds.com.au" />
              <meta property="og:image" content="https://www.geoffreynolds.com.au/assets/img/projects/bulli3.jpg" />
              <meta property="og:description" content="Geoff Reynolds Roofing Pty Ltd provides house cladding installation services in the Wollongong area. Visit our website to learn how we can serve you." />
              <meta property="og:site_name" content="Geoff Reynolds Roofing" />
                </Helmet>
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <center>  <div className="page-banner text-center">
                      <h1>Projec: Fairy Meadow</h1>
                      <ul className="page-breadcrumb">
                          <li><NavLink to="/">Home</NavLink>/<NavLink to="/">Projects</NavLink>/Fairy Meadow: Architectural Cladding</li>
                      </ul>
                  </div> </center>
                {/*====================  End of breadcrumb area  ====================*/}

                {/*====================  project details page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Projects section start*/}
                <div className="project-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-12 section-space--bottom--40">
                        <div className="project-image"><img src="assets/img/projects/KONE1936-HDR.jpg" className="img-fluid" alt="" /></div>
                        </div>
                        <div className="col-lg-4 col-12 section-space--bottom--30">
                            <div className="project-information">
                                <h3>Project Information</h3>
                                <ul>
                                    <li><strong>Products:</strong> <a href="project-details">Lysaght ZENITH Dominion</a></li>
                                    <li><strong>Location:</strong>Fairy Meadow NSW</li>
                                    <li><strong>Area:</strong>320 sqm</li>
                                    <li><strong>Year:</strong> 2018</li>
                                    <li><strong>Builder:</strong>Superior Builders</li>
                                    <li><strong>Extra info:</strong> <a href="project-details">Architectural Wall Cladding</a>, <a href="project-details">Residential</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                            <div className="project-details">
                            <h2>Zenith Dominion</h2>
                            <p>With a wide array of colours available, including the contemporary Colorbond steel colour range, the modern Colorbond steel Matt range, the superb Colorbond Metallic steel colour range, or as unpainted Zincalume steel, professionals are sure to find the perfect colour for their projects.</p>

                  <ul>
                  <li>
                  Unique, striking profile
                  </li>
                  <li>
                  Suitable for roofing or wall cladding
                  </li>
                  <li>
                  Wide range of colours available
                  </li>
                  <li>
                  Performance-tested, 100% Australian steel backed by warranty
                  </li>

                  </ul>
                            </div>
                        </div>
                        <div className="col-12">
                            <PhotoGallery3/>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Projects section end*/}

                </div>

                {/*====================  End of project details page content  ====================*/}

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default Projects;
