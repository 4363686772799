import React, { Component } from 'react';

class Sidebar extends Component{
    render(){

        let data = [
            {sidebarListLink: "residential", sidebarListIcon: 'flaticon-002-welding', sidebarListText: 'Residential Roofing'},
            {sidebarListLink: "architectural-cladding", sidebarListIcon: 'flaticon-004-walkie-talkie', sidebarListText: 'Architectural Cladding'},
            {sidebarListLink: "commercial", sidebarListIcon: 'flaticon-002-welding', sidebarListText: 'Commercial Roofing'},
            {sidebarListLink: "sheet-metal", sidebarListIcon: 'flaticon-004-walkie-talkie', sidebarListText: 'Sheet Metal Roofing'},

        ];

        let Datalist = data.map((val, i)=>{
            return(
                <li key={i}><a href={val.sidebarListLink}><i className={val.sidebarListIcon} />{val.sidebarListText}</a></li>
            )
        })

        return(
            <div>
                <div className="sidebar-wrapper">
                    <div className="sidebar">
                        <h3 className="sidebar-title">Services</h3>
                        <ul className="sidebar-list">
                            {Datalist}
                        </ul>
                    </div>
                    <div className="sidebar">
                        <h3 className="sidebar-title">Download brochure</h3>
                      
                        {/*==================== //<ul className="sidebar-list">
                          //  <li><a href="/"><i className="fa fa-file-pdf-o" />Brochures.PDF</a></li>
                            //<li><a href="/"><i className="fa fa-file-word-o" />Brochures.DOC</a></li>
                            //<a href="http://45.77.233.208/weblead" target="_blank" className="ht-btn ht-btn--default d-inline-block">GET A QUOTE</a>
                        //</ul>====================*/}
                    </div>
                </div>
            </div>
        )
    }
}

export default Sidebar;
