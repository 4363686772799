import React, { Component } from 'react';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import { NavLink } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { Helmet } from 'react-helmet';

const tagManagerArgs = {
  dataLayer: {
    userId: '001',
    userProject: 'project',
    page: 'Quote'
  },
  dataLayerName: 'architectural'
};

class Quote extends Component {
  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <div>
        <Helmet>
          <title>Quote Request - Geoff Reynolds Roofing</title>
          <meta
            name='description'
            content='Geoff Reynolds Roofing Pty Ltd provides metal roofing services in the Wollongong area. Visit our website to learn how we can serve you.'
          />
        </Helmet>

        {/* Navigation bar */}

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <center>
          {' '}
          <div className='page-banner text-center'>
            <h1>Quotation</h1>
            <ul className='page-breadcrumb'>
              <li>
                <NavLink to='/'>Home</NavLink>
              </li>
              <li>Quote</li>
            </ul>
          </div>{' '}
        </center>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  content page content ====================*/}
        <div className='page-wrapper section-space--inner--120'>
          {/*Contact section start*/}
          <div className='conact-section'>
            <div className='container'>
              <div className='row'>
                {/* <div className="col-lg-4 col-12">
                        <div className="contact-information">
                            <h3>Contact Information</h3>
                            <ul>
                                <li>
                                    <span className="icon"><i className="ion-android-map" /></span>
                                    <span className="text"><span>6/56 Montague Street, North Wollongong NSW 2500</span></span>
                                </li>
                                <li>
                                    <span className="icon"><i className="ion-ios-telephone-outline" /></span>
                                    <span className="text"><a href="tel:+61 242315040">02 4231 5040</a></span>
                                </li>
                                <li>
                                    <span className="icon"><i className="ion-ios-email-outline" /></span>
                                    <span className="text"><a href="mailto:info@geoffreynolds.com.au">info@geoffreynolds.com.au</a></span>
                                </li>
                            </ul>
                        </div>
                        </div> */}
                <div className='col-lg-8 col-12'>
                  <div className='contact-form'>
                    <center>
                      <h3>
                        Send us your plans at{' '}
                        <a href='mailto:quotation@geoffreynolds.com.au'>
                          <strong>quotation@geoffreynolds.com.au</strong>
                        </a>
                      </h3>
                    </center>
                    {/* <h5>Use Our Request a Quote portal below</h5>

                            <iframe title="Quote Request" height='800px' width='100%' frameborder='0' allowTransparency='true' scrolling='auto' src='https://creatorapp.zohopublic.com/grrapp/grroofing/form-embed/Request_a_quote/3M33v088uzkE71bvbOr6UBaEePaCht9ECvhy7DxyuUwBwB8MG4MT9dkG854z7BF2tzE7OVXm4pwx8gv7ad6ZC8K3xExjxNXyDzpw'></iframe> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Quote;
