import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
class VideoCta extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }

      openModal () {
        this.setState({isOpen: true})
      }
    render(){
        return(
            <div>
                {/*====================  video cta area ====================*/}
                <div className="video-cta-area section-space--inner--120">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="video-cta-content">
                            <h4 className="video-cta-content__small-title">ABOUT</h4>
                            <h3 className="video-cta-content__title">Our Roofing History</h3>
                            <p className="video-cta-content__text">With 30 years of experience, Geoff Reynolds Roofing Pty Ltd is a family owned and locally operated roofing business that specialises in new roof installation and complete re-roofing for both commercial and domestic properties.</p>
                            <NavLink to={`${process.env.PUBLIC_URL}/contact-us`} className="ht-btn ht-btn--round">CONTACT</NavLink>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-6">
                            <div className="cta-video-image">
                            <div className="video-popup">
                            {/*<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OrS-93U4AYQ' onClose={() => this.setState({isOpen: false})} />*/}
                                <button onClick={this.openModal}>
                                    <div className="cta-video-image__image">
                                        <img src="assets/img/projects/KONE2119-HDR-1.jpg" className="img-fluid" alt="Roofing in Wollongong" />
                                    </div>
                                  {/*  <div className="cta-video-image__icon">
                                        <i className="ion-ios-play" />
                                    </div>*/}
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of video cta area  ====================*/}
            </div>
        )
    }
}


export default VideoCta;
