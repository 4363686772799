import React, { Component } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { NavLink } from 'react-router-dom';
class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }
  getThisYear() {
    return new Date().getFullYear();
  }
  scrollToTop() {
    scroll.scrollToTop();
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector('nav');
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? document.getElementById('scroll-top').classList.add('show')
      : document.getElementById('scroll-top').classList.remove('show');
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  footer area ====================*/}
        <div className='footer-area dark-bg'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='footer-content-wrapper section-space--inner--100'>
                  <div className='row'>
                    <NavLink to={`${process.env.PUBLIC_URL}/`}>
                      <img
                        src='assets/img/logo/logo-light.svg'
                        className='img-fluid'
                        alt=''
                        id='footerLogo'
                      />
                    </NavLink>
                    <div className='col-xl-3 col-lg-3 col-md-12'>
                      {/* footer intro wrapper */}
                      <div className='footer-intro-wrapper'>
                        <div className='footer-logo'></div>
                      </div>
                    </div>

                    <div className='col-xl-2 offset-xl-1 col-lg-3 col-md-4'>
                      {/* footer widget */}
                      <div className='footer-widget mb-0'>
                        <h4 className='footer-widget__title'>Contact</h4>
                        <div className='footer-widget__content'>
                          <strong>
                            <p className='address'>
                              6/56 Montague Street, North Wollongong NSW 2500
                            </p>
                          </strong>
                          <ul className='contact-details'>
                            <li>
                              <span>P:</span>
                              <strong>02 4231 5040</strong>
                            </li>
                            <li>
                              <span>E:</span>
                              <strong>accounts@geoffreynolds.com.au</strong>
                            </li>
                          </ul>
                          <NavLink to={`${process.env.PUBLIC_URL}/contact-us`}>
                            <button id='contactFooter'>Contact</button>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='footer-copyright-wrapper'>
            <div className='footer-copyright text-center'>
              Copyright © {this.getThisYear()}. All right reserved
            </div>
          </div>
        </div>
        {/*====================  End of footer area  ====================*/}
        {/*====================  scroll top ====================*/}
        <button
          className='scroll-top'
          id='scroll-top'
          onClick={this.scrollToTop}
        >
          <i className='ion-android-arrow-up' />
        </button>
        {/*====================  End of scroll top  ====================*/}
      </div>
    );
  }
}

export default Footer;
