import React, {Component} from 'react';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {Link, NavLink} from 'react-router-dom';
import { Helmet } from "react-helmet";

class Projects extends Component{
    render(){

        let data = [
            {pageLink: 'bulli', img: 'bulli2.jpg', projectTitle: 'Bulli: Architectural Cladding:', projectSubtitle: 'Builder: Clayton Constructions, Product: Lysaght ZENITH Dominion'},
            {pageLink: 'corrimal', img: 'KONE1824-HDR.jpg', projectTitle: 'Corrimal: Architectural Wall Cladding:', projectSubtitle: 'Product: Lysaght ZENITH Snapseam & Colorbond, Builder: Smartbuild'},
            {pageLink: 'fairy-meadow', img: 'KONE1908-HDR.jpg', projectTitle: 'Fairy Meadow: Architectural Wall Cladding', projectSubtitle: 'Product: Lysaght ZENITH Dominion & Colorbond, Builder: Superior Builders'},
            {pageLink: 'keiraville', img: 'KONE2119-HDR.jpg', projectTitle: 'Keiraville', projectSubtitle: 'Product: Lysaght ZENITH Enseam & Colorbond, Builder: Moretti Const'},
            {pageLink: 'kiama', img: 'KONE2170-HDR.jpg', projectTitle: 'Kiama', projectSubtitle: 'Product: Lysaght ZENITH Longline & Colorbond, Builder: Smartbuild'},
            {pageLink: 'kiama-nsw', img: 'KONE2221-HDR.jpg', projectTitle: 'Kiama: Architectural Wall Cladding', projectSubtitle: 'Product: Lysaght ZENITH Longline & Colorbond, Builder:  Smartbuild'},
            {pageLink: 'shellharbour', img: 'KONE2371-HDR.jpg', projectTitle: 'Shellharbour: Wall Cladding', projectSubtitle: 'Product: Lysaght ZENITH Longline & Colorbond, Builder:  Smartbuild'},

        ];

        let Datalist = data.map((val, i)=> {
            return(
                <div className="col-lg-4 col-sm-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item service-grid-item--style2">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                            <img src={`assets/img/projects/${val.img}`} className="img-fluid" alt="" />
                        </Link>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.projectTitle}</Link>
                        </h3>
                        <p className="subtitle">{val.projectSubtitle}</p>
                        <Link to={`${process.env.PUBLIC_URL}/${val.pageLink}`} className="see-more-link">MORE</Link>
                    </div>
                    </div>
                </div>
            )
        });

        return(
          <div>
          <Helmet>
            <title>Projects - Geoff Reynolds Roofing</title>
            <meta name="description" content="Geoff Reynolds Roofing Pty Ltd provides metal roofing services in the Wollongong area. Visit our website to learn how we can serve you." />

          </Helmet>
              {/* Navigation bar */}
            <center>  <div className="page-banner text-center">
                  <h1>Projects</h1>
                  <ul className="page-breadcrumb">
                      <li><NavLink to="/">Home</NavLink></li>
                      <li>Projects</li>
                  </ul>
              </div> </center>

              {/*=============== =====  service page content ====================*/}
              <div className="page-wrapper section-space--inner--120">
              {/*Service section start*/}
              <div className="service-section">
                  <div className="container">
                  <div className="row">
                      <div className="col-lg-12">
                      <div className="service-item-wrapper">
                          <div className="row">

                              {Datalist}
                          </div>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              {/*Service section end*/}
              </div>

              {/*====================  End of service page content  ====================*/}

              {/* Brand logo */}
              <BrandLogoSlider background = "grey-bg" />

              {/* Footer */}
              <Footer/>

              {/* Mobile Menu */}
              <MobileMenu/>

          </div>
        )
    }
}


export default Projects;
