import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [success, setSuccess] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [thisRecaptcha, setThisRecaptcha] = useState({});
  const [clicked2, setClicked2] = useState('show');
  const [error, setError] = useState('');
  const [thisResponse, setThisResponse] = useState({});
  const [fill, setFill] = useState(false);
  const [clicked3, setClicked3] = useState(false);

  const Contact = {
    Details: {
      name: name,
      email: email,
      phone: phone,

      message: message,
      captcha: thisRecaptcha
    }
  };
  function clickOnContact() {
    setClicked3(true);
    name && email && message ? sendContact(Contact) : setFill(true);
  }

  async function sendContact(Contact) {
    setClicked2('loading');
    const response = await fetch(
      'https://us-central1-test-9ecfd.cloudfunctions.net/contact',
      {
        method: 'POST',
        body: JSON.stringify(Contact),
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(res => res.json())
      .then(data => {
        // console.log(data);
        // console.log(clicked2);
        {
          data.test === 'captcha passed'
            ? setClicked2('success')
            : setError(data.message);
        }

        setThisResponse(data);
      })
      .catch(error => {
        setClicked2('error');
        setError(error.message);
        //   console.log(error.message);
      });
  }
  function onChange(value) {
    setThisRecaptcha(value);
  }
  return (
    <div>
      {' '}
      <div id='employmentScreen'>
        <div id='employmentForm'>
          <Helmet>
            <title>Contact | Geoff Reynolds Roofing</title>
            <meta
              name='description'
              content='Contact us for all your roofing needs.'
            />
          </Helmet>

          {fill && !name && !email && !message ? (
            <p id='error'>Please fill in all fields</p>
          ) : null}
          {thisRecaptcha === '' && clicked3 ? (
            <p id='error'>Please complete the reCAPTCHA</p>
          ) : null}
          {clicked2 == 'error' ? (
            <p id='success'>ERROR...please report this issue.</p>
          ) : null}
          {clicked2 == 'loading' ? <p id='success'>Loading...</p> : null}
          {clicked2 == 'show' ? (
            <>
              <div id='inputItem'>
                <label>Name</label>
                <input
                  type='text'
                  value={name}
                  onChange={e => setName(e.target.value)}
                  placeholder='Your Full Name'
                />
              </div>
              <div id='inputItem'>
                <label>Email</label>
                <input
                  type='text'
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder='Your Email Address'
                />
              </div>
              <div id='inputItem'>
                <label>Phone</label>
                <input
                  type='text'
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                  placeholder='Your Phone Number'
                />
              </div>

              <div id='inputItem'>
                <label>Message</label>
                <textarea
                  type='text'
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                  placeholder='Write your message/position here'
                />
              </div>
            </>
          ) : (
            <>DONE</>
          )}

          <br></br>
        </div>
        <div>
          <ReCAPTCHA
            sitekey='6LeweQAgAAAAAATVEucjTpWSuFWGQlc3O5EEKkG_'
            onChange={onChange}
          />
        </div>
        <button id='submitButton' onClick={clickOnContact}>
          Submit
        </button>
        <br></br>
        <br></br>

        {clicked2 == 'success' ? (
          <p id='success'>Thanks. We'll contact you soon.</p>
        ) : null}

        {success && (
          <div>
            <p>Name: {name}</p>
            <p>Email: {email}</p>
            <p>Phone: {phone}</p>
            <p>Message: {message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactForm;
