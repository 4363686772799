import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

import { NavLink } from 'react-router-dom';

class HeroSliderOne extends Component {
  render() {
    const params = {
      slidesPerView: 1,
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      effect: 'fade',
      navigation: {
        nextEl: '.ht-swiper-button-next',
        prevEl: '.ht-swiper-button-prev'
      },
      renderPrevButton: () => (
        <div className='ht-swiper-button-prev ht-swiper-button-nav d-none d-xl-block'>
          <i className='ion-ios-arrow-left' />
        </div>
      ),
      renderNextButton: () => (
        <div className='ht-swiper-button-next ht-swiper-button-nav d-none d-xl-block'>
          <i className='ion-ios-arrow-forward' />
        </div>
      ),
      autoplay: {
        delay: 5000
      }
    };

    let data = [
      {
        bgImg: 'KONE1864-HDR.jpg',
        sliderTitle: 'Architectural Cladding',
        sliderSubtitle:
          'We specialise in architectural wall cladding. You can choose from th wide range of Lysaght ZENITH™ and Colorbond products.',
        btnLink: 'architectural-cladding'
      },
      {
        bgImg: 'KONE2119-HDR.jpg',
        sliderTitle: 'Commercial Roofing',
        sliderSubtitle:
          'Our roofers are highly skilled and experienced specialists and work on large projects. So whatever the roofing need is, we are sure we can help. We offer fast quotation to keep your project up to date. ',
        btnLink: 'commercial'
      },
      {
        bgImg: 'KONE1970.jpg',
        sliderTitle: 'Residential Roofing',
        sliderSubtitle:
          'We can offer our 30 years of experience with 800 sucessful projecs.',
        btnLink: 'residential'
      },
      {
        bgImg: 'KONE1825-HDR.jpg',
        sliderTitle: 'Sheet Metal Roofing',
        sliderSubtitle:
          'Specialised in new sheet metal roof installation and complete re-roofing. Check our options or Contact for more.',
        btnLink: 'sheet-metal'
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className='swiper-slide' key={i}>
          <div
            className='hero-slider__single-item'
            style={{ backgroundImage: `url(assets/img/projects/${val.bgImg})` }}
          >
            <div className='hero-slider__content-wrapper'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <div className='hero-slider__content'>
                      <h1 className='hero-slider__title'>{val.sliderTitle}</h1>
                      <p className='hero-slider__text'>{val.sliderSubtitle}</p>
                      {/* <NavLink
                        className='hero-slider__btn'
                        to={`${process.env.PUBLIC_URL}/${val.btnLink}`}
                      >
                        More
                      </NavLink> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {/*====================  hero slider area ====================*/}
        <div className='hero-alider-area'>
          <Swiper {...params}>{DataList}</Swiper>
        </div>
        {/*====================  End of hero slider area  ====================*/}
      </div>
    );
  }
}

export default HeroSliderOne;
