import React, { Component } from 'react';
import { LightgalleryProvider, LightgalleryItem } from 'react-lightgallery';

class PhotoGalleryMain extends Component {
  render() {
    /* project gallery image list */
    const PROJECT_IMAGES = [
      'KONE2366.jpg',
      'KONE2312.jpg',
      'KONE2294.jpg',
      'KONE2251-HDR.jpg',
      'KONE2184-HDR.jpg',
      'KONE2176-HDR.jpg',
      'KONE1994.jpg',
      'KONE1970.jpg',
      'KONE1952.jpg',
      'KONE1842-HDR.jpg',
      'KONE1780.jpg',
      'KONE1824-HDR.jpg',
      'KONE1825-HDR.jpg',
      'KONE2371-HDR.jpg',
      'KONE2291.jpg',
      'KONE2273.jpg',
      'KONE2260.jpg',
      'KONE2233-HDR.jpg',
      'KONE2221-HDR.jpg',
      'KONE2200-HDR.jpg',
      'KONE2192.jpg',
      'KONE2170-HDR.jpg',
      'KONE2140-HDR.jpg',
      'KONE2119-HDR.jpg',
      'KONE2101-HDR.jpg',
      'KONE1941-HDR.jpg',
      'KONE1936-HDR.jpg',
      'KONE1908-HDR.jpg',
      'KONE1864-HDR.jpg',
      'KONE1824-HDR.jpg',
      'KONE1825-HDR.jpg'
    ];

    const PhotoItem = ({ image, group }) => (
      <div className='col-xl-3 col-lg-4 col-sm-6 col-12 section-space--top--10'>
        <LightgalleryItem group={group} src={`assets/img/projects/${image}`}>
          <button className='gallery-item single-gallery-thumb'>
            <img
              src={`assets/img/projects/${image}`}
              className='img-fluid'
              alt=''
            />
            <span className='plus' />
          </button>
        </LightgalleryItem>
      </div>
    );

    return (
      <div>
        <LightgalleryProvider>
          <div className='row row-5'>
            {PROJECT_IMAGES.map((p, idx) => (
              <PhotoItem key={idx} image={p} group='group1' />
            ))}
          </div>
        </LightgalleryProvider>
      </div>
    );
  }
}

export default PhotoGalleryMain;
