import React, {Component} from 'react';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import {NavLink} from 'react-router-dom';
import { Helmet } from "react-helmet";

import MobileMenu from '../components/MobileMenu';
class Services extends Component{
    render(){

        let data = [
            {pageLink: 'architectural-cladding', img: 'KONE2251-HDR.jpg', iconClass: 'flaticon-002-welding', serviceTitle: 'Architectural Cladding', serviceSubtitle: 'We are experts in Architectural Cladding. We are using Lysaght ZENIT product line. Using latest techniques and practices.'},
            {pageLink: 'residential', img: 'KONE2233-HDR.jpg', iconClass: 'flaticon-004-walkie-talkie', serviceTitle: 'Residential Roofing', serviceSubtitle: 'Our commercial roofing experience dates 25 years ago. We can manage any size of project in efficient time frime.'},
            {pageLink: 'commercial', img: 'KONE1824-HDR.jpg', iconClass: 'flaticon-002-welding', serviceTitle: 'Commercial Roofing', serviceSubtitle: 'Tailor-made metal roofing solutions for new metal roofs, re-roofs, roof servicing and repairs. Weatherproof roofing suitable for all sizes and designs of residential building.'},
            {pageLink: 'sheet-metal', img: 'KONE2200-HDR.jpg', iconClass: 'flaticon-002-welding', serviceTitle: 'Sheet Metal Roofing', serviceSubtitle: 'Specialised in new roof installation and complete re-roofing'},

        ];

        let Datalist = data.map((val, i) => {
            return(
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key={i}>
                    <div className="service-grid-item">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <NavLink to={`${process.env.PUBLIC_URL}/${val.pageLink}`}>
                            <img src={`assets/img/projects/${val.img}`} className="img-fluid" alt="Service Grid" />
                        </NavLink>
                        </div>
                        <div className="icon">
                        <i className={val.iconClass} />
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <NavLink to={`${process.env.PUBLIC_URL}/${val.pageLink}`}>{val.serviceTitle}</NavLink>
                        </h3>
                        <p className="subtitle">{val.serviceSubtitle}</p>
                        <NavLink to={`${process.env.PUBLIC_URL}/${val.pageLink}`} className="see-more-link">More</NavLink>
                    </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
            <Helmet>
              <title>Services - Geoff Reynolds Roofing</title>
              <meta name="description" content="Geoff Reynolds Roofing Pty Ltd provides metal roofing services in the Wollongong area. Visit our website to learn how we can serve you." />

            </Helmet>
                {/* Navigation bar */}
              <center>  <div className="page-banner text-center">
                    <h1>Services</h1>
                    <ul className="page-breadcrumb">
                        <li><NavLink to="/">Home</NavLink></li>
                        <li>Services</li>
                    </ul>
                </div> </center>

                {/*====================  service page content ====================*/}
                <div className="page-wrapper section-space--inner--120">
                {/*Service section start*/}
                <div className="service-section">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-item-wrapper">
                            <div className="row">

                                {Datalist}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                {/*Service section end*/}
                </div>

                {/*====================  End of service page content  ====================*/}

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}

export default Services;
