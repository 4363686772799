import React, {Component} from 'react';
import Sidebar from './components/Sidebar';
import ServiceGallery from './components/ServiceGallery';
import BrandLogoSlider from '../components/BrandLogoSlider';
import {NavLink} from 'react-router-dom';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import { Helmet } from "react-helmet";

import TagManager from 'react-gtm-module';
const tagManagerArgs = {
      dataLayer: {
          userId: '001',
          userProject: 'project',
          page: 'commercial-roofing'
      },
      dataLayerName: 'architectural'
  };
class ServiceDetailsLeftSidebar extends Component{

    render(){
      TagManager.dataLayer(tagManagerArgs);
        return(
            <div>
                {/* Navigation bar */}


                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                {/*        <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>Commercial Roofing</h1>
                                    <ul className="page-breadcrumb">
                                         <li><NavLink to={`${process.env.PUBLIC_URL}/`}>Home</NavLink></li>
                                        <li><NavLink to={`${process.env.PUBLIC_URL}/services`}>Services</NavLink></li>
                                        <li>Commercial Roofing</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>=*/}
                {/*====================  End of breadcrumb area  ====================*/}
                <Helmet>
                  <title>Commercial Roofing in Wollongong - Geoff Reynolds Rooing</title>
                  <meta name="description" content="Geoff Reynolds Roofing Pty Ltd provides commercial roofing services in the Wollongong area. Visit our website to learn how we can serve you." />

                <meta itemprop="name" content="Commercial Roofing in Wollongong" />
                <meta itemprop="description" content="Geoff Reynolds Roofing Pty Ltd provides commercial roofing services in the Wollongong area. Visit our website to learn how we can serve you." />
                <meta itemprop="image" content="https://www.geoffreynolds.com.au/assets/img/projects/bulli3.jpg" /><meta name="keywords" content="metal roofing, house cladding, architectural cladding, wollongong"/>

                <meta name="twitter:card" content="https://www.geoffreynolds.com.au/assets/img/projects/bulli3.jpg" />
                <meta name="twitter:title" content="Commercial Roofing Installation in Wollongong" />
                <meta name="twitter:description" content="Geoff Reynolds Roofing Pty Ltd provides commercial roofing services in the Wollongong area. Visit our website to learn how we can serve you." />
                <meta name="twitter:image:src" content="https://www.geoffreynolds.com.au/assets/img/projects/bulli3.jpg" />

                <meta property="og:title" content="Commercial Roofing Installation in Wollongong" />
                <meta property="og:type" content="Site" />
                <meta property="og:url" content="https://www.geoffreynolds.com.au" />
                <meta property="og:image" content="https://www.geoffreynolds.com.au/assets/img/projects/bulli3.jpg" />
                <meta property="og:description" content="Geoff Reynolds Roofing Pty Ltd provides commercial roofing services in the Wollongong area. Visit our website to learn how we can serve you." />
                <meta property="og:site_name" content="Geoff Reynolds Roofing" />
                </Helmet>
                <div className="page-wrapper section-space--inner--120">
                    {/*Service section start*/}
                    <div className="service-section">
                        <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-12 order-1 order-lg-2">
                            <div className="service-details">
                                {/* service gallery */}
                                <center><h1>Commercial Roofing</h1></center>
                                <ul className="page-breadcrumb">
                                    <li><NavLink to={`${process.env.PUBLIC_URL}/`}>Home</NavLink></li>
                                    <li><NavLink to={`${process.env.PUBLIC_URL}/services`}>Services</NavLink></li>
                                    <li>Architectural Cladding</li>
                                </ul>
                                <ServiceGallery/>

                                <div className="content section-space--top--30">
                                <div className="row">
                                    <div className="col-12">
                                    <p>Are you having trouble with an old, damaged or leaking roof? Perhaps you’re building a new home or extending your existing property? When you’re looking for a brand new, high-quality roof, it always pays to get in touch with Wollongong’s reliable roofing specialists.</p>
                                    <p>Are you having trouble with an old, damaged or leaking roof? Perhaps you’re building a new home or extending your existing property? When you’re looking for a brand new, high-quality roof, it always pays to get in touch with Wollongong’s reliable roofing specialists.</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Roofing Info 1</h3>
                                    <p>Are you having trouble with an old, damaged or leaking roof? Perhaps you’re building a new home or extending your existing property?</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Roofing Info 2  </h3>
                                    <p>Are you having trouble with an old, damaged or leaking roof? Perhaps you’re building a new home or extending your existing property?</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Roofing Info 3</h3>
                                    <p>Are you having trouble with an old, damaged or leaking roof? Perhaps you’re building a new home or extending your existing property?</p>
                                    </div>
                                    <div className="col-lg-6 col-12 section-space--top--30">
                                    <h3>Project Strategy</h3>
                                    <p>Are you having trouble with an old, damaged or leaking roof? Perhaps you’re building a new home or extending your existing property?</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-4 col-12 order-2 order-lg-1">
                                <Sidebar />
                            </div>
                        </div>
                        </div>
                    </div>
                    {/*Service section end*/}
                    </div>

                {/* Brand logo */}
                <BrandLogoSlider background = "grey-bg" />

                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default ServiceDetailsLeftSidebar;
