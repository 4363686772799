import React, { Component } from 'react';
import ServiceGallery from '../service/components/ServiceGallery';

class TeamJob extends Component {
  render() {
    let data = [
      {
        jobTitle: 'Colorbond',
        jobDesc: 'Check what Colorbond offers',
        jobLink: 'https://www.geoffreynolds.com.au/gallery'
      },
      {
        jobTitle: 'Lysaght',
        jobDesc: 'Lysaght products',
        jobLink: 'https://www.geoffreynolds.com.au/gallery'
      },
      {
        jobTitle: 'Request a quote',
        jobDesc: 'Send us your plans',
        jobLink: 'https://www.geoffreynolds.com.au/quote'
      },
      {
        jobTitle: 'Contact',
        jobDesc: 'Send us a message here or call us on 02 4231 5040',
        jobLink: 'https://www.geoffreynolds.com.au/contact-us'
      },
      {
        jobTitle: 'Check us on Instagram',
        jobDesc:
          'We are regularly posting cool photos from our favorite projects. Check us out!',
        jobLink: 'https://www.instagram.com/geoffreynoldsroofing/'
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className='team-job__single' key={i}>
          <h3 className='title'>
            {' '}
            <a href={val.jobLink}>{val.jobTitle}</a>
          </h3>
          <p className='text'>{val.jobDesc}</p>
        </div>
      );
    });
    return (
      <div>
        {/*====================  team job area ====================*/}
        <div className='team-job-area section-space--inner--120'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-6'>
                <div className='team-job__content'>
                  <div className='team-job__title-wrapper'>
                    <h2 className='team-job__title'>
                      Our range
                      <br />
                      of services and suppliers.
                    </h2>
                    <button
                      onClick={null}
                      className='see-more-link see-more-link--color'
                    >
                      Browse
                    </button>
                  </div>
                  <div className='team-job__content-wrapper'>
                    <h2 className='team-job__title'>
                      View
                      <span>
                        <button
                          onClick={null}
                          className='see-more-link see-more-link--color'
                        ></button>
                      </span>
                    </h2>
                    <div className='team-job__list-wrapper'>{DataList}</div>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='team-job__image text-center text-lg-right'>
                  <ServiceGallery />
                  {/*<img src="assets/img/team/team.jpg" className="img-fluid" alt="" />*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of team job area  ====================*/}
      </div>
    );
  }
}

export default TeamJob;
