import React, { Component } from 'react';

class BackHome extends Component{

    render(){
        return(
            <div>

            <h4 style={{marginTop: '100px', marginLeft: '150px'}}><a href={`${process.env.PUBLIC_URL}/`}>Back to home</a></h4>
                {/*====================  about text area ====================*/}

                {/*====================  End of about text area  ====================*/}
            </div>
        )
    }
}


export default BackHome;
