import React, {Component} from 'react';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import {NavLink,useParams} from 'react-router-dom';
import { Helmet } from "react-helmet";



class Sign extends Component{
     idFunction(){
        let { id } = useParams();
         console.log("hereidFunction");
     }       
   
    render(){

        return(


                 <div>

           
                <div className="page-wrapper section-space--inner--120">
                <div className="conact-section">
                    <div className="container">
                    <div className="row">
                       

                        <div className="contact-form">
                            <h5>To sign this contract Scroll down to the end, press on the blank Signature space and draw your Signature. If you wish to add any notes, please feel free to write in the Notes section. </h5>

<iframe title="Sign"height='750px' width='400px' frameborder='0' allowTransparency='true' scrolling='auto' src={"https://creatorapp.zohopublic.com/grrapp/grroofing/form-embed/Contracts_Confimed/5NT4tHK4zjp9zEWxNB7gVtCgd4FfP04V1zH63my5tjM3bduHdMWDdWkwUGMUjV4ZgRTBDysvXaaU96Ye9z3B8PPeFGwQXFWVg8Zt?contractNumber=" + this.props.match.params.id} ></iframe>                        </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                

                

        )
    }
}


export default Sign;
