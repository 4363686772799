import React, { Component } from 'react';
import MobileMenu from './MobileMenu';
import { Link, NavLink } from 'react-router-dom';

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);

    this.mobileMenuElement = React.createRef();
  }

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  };

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector('nav');
    this.setState({ top: el.offsetTop + 30, height: el.offsetHeight });
    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height * 2}px`)
      : (document.body.style.paddingTop = 0);
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  header area ====================*/}
        <div
          className={`header-area header-sticky header-sticky--default ${
            this.state.scroll > this.state.top ? 'is-sticky' : ''
          }`}
        >
          <div className='header-area__desktop header-area__desktop--default'>
            {/*=======  header top bar  =======*/}
            <div className='header-top-bar'>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-lg-4'>
                    {/* top bar left */}
                    <div className='top-bar-left-wrapper'>
                      <a href='mailto:info@geoffreynolds.com.au'>
                        <p>info@geoffreynolds.com.au</p>
                      </a>
                    </div>
                  </div>
                  <div className='col-lg-8'>
                    {/* top bar right */}
                    <div className='top-bar-right-wrapper'>
                      <NavLink
                        to={`${process.env.PUBLIC_URL}/quote`}
                        className='ht-btn ht-btn--default d-inline-block'
                      >
                        GET A QUOTE
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header top bar  =======*/}
            {/*=======  header info area  =======*/}
            <div className='header-info-area'>
              <div className='container'>
                <div className='row align-items-center'>
                  <div className='col-lg-12'>
                    <div className='header-info-wrapper align-items-center'>
                      {/* logo */}
                      <div className='logo'>
                        <NavLink to={`${process.env.PUBLIC_URL}/`}>
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/img/logo/logo.svg`}
                            className='img-fluid'
                            alt='Logo'
                            width='240'
                          />
                        </NavLink>
                      </div>
                      {/* header contact info */}
                      <div className='header-contact-info'>
                        <div className='header-info-single-item'>
                          <div className='header-info-single-item__icon'>
                            <i className='zmdi zmdi-smartphone-android' />
                          </div>
                          <div className='header-info-single-item__content'>
                            <h6 className='header-info-single-item__title'>
                              Phone
                            </h6>
                            <p className='header-info-single-item__subtitle'>
                              02 4231 5040
                            </p>
                          </div>
                        </div>
                        <div className='header-info-single-item'>
                          <div className='header-info-single-item__icon'>
                            <i className='zmdi zmdi-home' />
                          </div>
                          <div className='header-info-single-item__content'>
                            <h6 className='header-info-single-item__title'>
                              Address
                            </h6>
                            <p className='header-info-single-item__subtitle'>
                              6/56 Montague Street, North Wollongong NSW 2500
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* mobile menu */}
                      <div
                        className='mobile-navigation-icon'
                        id='mobile-menu-trigger'
                        onClick={this.activeMobileMenu}
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header info area =======*/}
            {/*=======  header navigation area  =======*/}
            <div className='header-navigation-area default-bg'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    {/* header navigation */}
                    <div className='header-navigation header-navigation--header-default position-relative'>
                      <div className='header-navigation__nav position-static'>
                        <nav>
                          <ul>
                            <li>
                              <NavLink
                                to={`${process.env.PUBLIC_URL}/`}
                                exact={true}
                              >
                                Home
                              </NavLink>
                              {/*     <ul className="submenu">
                                                    <li><a href={`${process.env.PUBLIC_URL}/home-one`}>Homepage One</Link></li>
                                                    <li><a href={`${process.env.PUBLIC_URL}/home-two`}>Homepage Two</Link></li>
                                                    <li><a href={`${process.env.PUBLIC_URL}/home-three`}>Homepage Three</Link></li>
                                                </ul>*/}
                            </li>
                            <li>
                              {' '}
                              <NavLink
                                to={`${process.env.PUBLIC_URL}/about-us`}
                              >
                                About
                              </NavLink>
                            </li>

                            <li>
                              <NavLink to={`${process.env.PUBLIC_URL}/gallery`}>
                                Gallery
                              </NavLink>
                              {/*  <ul className="submenu">
                                                <li><a href={`${process.env.PUBLIC_URL}/blog-left-sidebar`}>Blog Left Sidebar</Link></li>
                                                <li><a href={`${process.env.PUBLIC_URL}/blog-right-sidebar`}>Blog Right Sidebar</Link></li>
                                                <li><a href={`${process.env.PUBLIC_URL}/blog-details-left-sidebar`}>Blog Details Left Sidebar</Link></li>
                                                <li><a href={`${process.env.PUBLIC_URL}/blog-details-right-sidebar`}>Blog Details Right Sidebar</Link></li>
                                                </ul>*/}
                            </li>

                            <li>
                              {' '}
                              <NavLink
                                to={`${process.env.PUBLIC_URL}/contact-us`}
                              >
                                Contact
                              </NavLink>
                            </li>
                            <li>
                              {' '}
                              <NavLink
                                to={`${process.env.PUBLIC_URL}/employment`}
                              >
                                Employment
                              </NavLink>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header navigation area =======*/}
          </div>
        </div>
        {/*====================  End of header area  ====================*/}

        {/* Mobile Menu */}
        <MobileMenu ref={this.mobileMenuElement} />
      </div>
    );
  }
}

export default NavBar;
