import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

class MobileMenu extends Component {
  state = {
    active: false
  };

  toggleMobileMenu = () => {
    this.setState({
      active: !this.state.active
    });
  };

  componentDidMount() {
    var offCanvasNav = document.getElementById('offcanvas-navigation');
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll('.sub-menu');

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        'beforebegin',
        "<span class='menu-expand'><i></i></span>"
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll('.menu-expand');
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains('active') === true) {
        this.parentElement.classList.remove('active');
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove('active');
        }
        this.parentElement.classList.add('active');
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener('click', sideMenuExpand);
    }
  }

  render() {
    return (
      <div>
        {/*=======  offcanvas mobile menu  =======*/}
        <div
          className={`offcanvas-mobile-menu ${
            this.state.active ? 'active' : ''
          } `}
          id='mobile-menu-overlay'
        >
          <NavLink
            to={`${process.env.PUBLIC_URL}/`}
            className='offcanvas-menu-close'
            id='mobile-menu-close-trigger'
            onClick={this.toggleMobileMenu}
          >
            <i className='ion-android-close' />
          </NavLink>
          <div className='offcanvas-wrapper'>
            <div className='offcanvas-inner-content'>
              <nav className='offcanvas-navigation' id='offcanvas-navigation'>
                <ul>
                  <li>
                    <NavLink
                      onClick={this.toggleMobileMenu}
                      to={`${process.env.PUBLIC_URL}/`}
                      exact={true}
                    >
                      Home
                    </NavLink>
                    {/*     <ul className="submenu">
                                                    <li><a href={`${process.env.PUBLIC_URL}/home-one`}>Homepage One</Link></li>
                                                    <li><a href={`${process.env.PUBLIC_URL}/home-two`}>Homepage Two</Link></li>
                                                    <li><a href={`${process.env.PUBLIC_URL}/home-three`}>Homepage Three</Link></li>
                                                </ul>*/}
                  </li>
                  <li>
                    {' '}
                    <NavLink
                      onClick={this.toggleMobileMenu}
                      to={`${process.env.PUBLIC_URL}/about-us`}
                    >
                      About
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      onClick={this.toggleMobileMenu}
                      to={`${process.env.PUBLIC_URL}/gallery`}
                    >
                      Gallery
                    </NavLink>
                    {/*  <ul className="submenu">
                                                <li><a href={`${process.env.PUBLIC_URL}/blog-left-sidebar`}>Blog Left Sidebar</Link></li>
                                                <li><a href={`${process.env.PUBLIC_URL}/blog-right-sidebar`}>Blog Right Sidebar</Link></li>
                                                <li><a href={`${process.env.PUBLIC_URL}/blog-details-left-sidebar`}>Blog Details Left Sidebar</Link></li>
                                                <li><a href={`${process.env.PUBLIC_URL}/blog-details-right-sidebar`}>Blog Details Right Sidebar</Link></li>
                                                </ul>*/}
                  </li>

                  <li>
                    {' '}
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/contact-us`}
                      onClick={this.toggleMobileMenu}
                    >
                      Contact
                    </NavLink>
                  </li>
                  <li>
                    {' '}
                    <NavLink
                      to={`${process.env.PUBLIC_URL}/employment`}
                      onClick={this.toggleMobileMenu}
                    >
                      Employment
                    </NavLink>
                  </li>
                </ul>
              </nav>
              <div className='offcanvas-widget-area'>
                <div className='off-canvas-contact-widget'>
                  <NavLink
                    to={`${process.env.PUBLIC_URL}/quote`}
                    className='ht-btn ht-btn--default d-inline-block'
                    onClick={this.toggleMobileMenu}
                  >
                    GET A QUOTE
                  </NavLink>
                </div>
              </div>
              <div className='offcanvas-widget-area'>
                <div className='off-canvas-contact-widget'>
                  <div className='header-contact-info'>
                    <ul className='header-contact-info__list'>
                      <li>
                        <i className='ion-android-phone-portrait' />{' '}
                        <a href='tel://+61242315040'>02 4231 5040</a>
                      </li>
                      <li>
                        <i className='ion-android-mail' />{' '}
                        <a href='mailto:info@geoffreynolds.com.au'>
                          info@geoffreynolds.com.au
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Off Canvas Widget Social Start*/}
                <div className='off-canvas-widget-social'>
                  <a
                    href='https://facebook.com/geoffreynoldsroofingau'
                    title='Facebook'
                  >
                    <i className='fa fa-facebook' /> Geoff Reynolds Roofing
                  </a>
                  <a
                    href='https://instagram.com/geoffreynoldsroofing'
                    title='Instagram'
                  >
                    <i className='fa fa-instagram' /> @geoffreynoldsroofing
                  </a>
                </div>
                {/*Off Canvas Widget Social End*/}
              </div>
            </div>
          </div>
        </div>
        {/*=======  End of offcanvas mobile menu  =======*/}
      </div>
    );
  }
}

export default MobileMenu;
