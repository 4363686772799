import React, { Component } from 'react';
import HeroSliderOne from './components/HeroSliderOne';
import ServiceGridSlider from './components/ServiceGridSlider';
import Funfact from './components/Funfact';
import VideoCta from './components/VideoCta';
import ProjectSlider from './components/ProjectSlider';
import { Helmet } from 'react-helmet';

import { NavLink } from 'react-router-dom';
import TeamJob from './components/TeamJob';
import TestimonialSlider from './components/TestimonialSlider';
import BrandLogoSlider from './components/BrandLogoSlider';
import Footer from './components/Footer';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  dataLayer: {
    userId: '001',
    userProject: 'project',
    page: 'home'
  },
  dataLayerName: 'architectural'
};
class HomeOne extends Component {
  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <div>
        {/* Navigation bar */}

        {/* Hero slider */}
        <HeroSliderOne />

        {/* Service grid slider */}
        <ServiceGridSlider />

        {/* Fun fact */}

        {/* Video CTA */}
        <VideoCta />

        {/* Project Slider */}
        {/* <ProjectSlider/> */}

        {/* Team job */}
        <TeamJob />

        {/* Testimonial Slider */}

        {/* Blog grid */}

        {/* Brand logo */}
        <BrandLogoSlider background='' />

        {/* Footer */}
        <Footer />
      </div>
    );
  }
}

export default HomeOne;
