import React, { Component } from 'react';
import FeatureIcon from '../components/FeatureIcon';
import Funfact from '../components/Funfact';
import TeamMemberGrid from '../components/TeamMemberGrid';
import TestimonialSlider from '../components/TestimonialSlider';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import MobileMenu from '../components/MobileMenu';

import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  dataLayer: {
    userId: '001',
    userProject: 'project',
    page: 'about'
  },
  dataLayerName: 'architectural'
};
class About extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <div>
        <Helmet>
          <title>About - Geoff Reynolds Roofing</title>
          <meta
            name='description'
            content='Geoff Reynolds Roofing Pty Ltd provides metal roofing services in the Wollongong area. Visit our website to learn how we can serve you.'
          />
        </Helmet>
        {/* Navigation bar */}

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <center>
          {' '}
          <div className='page-banner text-center'>
            <h1>About</h1>
            <ul className='page-breadcrumb'>
              <li>
                <NavLink to='/'>Home</NavLink>
              </li>
              <li>About</li>
            </ul>
          </div>{' '}
        </center>
        {/*====================  End of breadcrumb area  ====================*/}

        <div className='page-wrapper section-space--inner--top--120'>
          {/*About section start*/}
          <div className='about-section section-space--inner--bottom--120'>
            <div className='container'>
              <div className='row row-25 align-items-center'>
                <div className='col-lg-6 col-12 mb-30'>
                  <div className='about-image-two'>
                    <img src='assets/img/projects/IMG_6495.jpg' alt='' />
                    <span className='video-popup'>
                      {/*<ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='OrS-93U4AYQ' onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <i className="ion-ios-play-outline" />
                                </button>*/}
                    </span>
                  </div>
                </div>
                <div className='col-lg-6 col-12 mb-30'>
                  <div className='about-content-two'>
                    <h3>Welcome to Geoff Reynolds Roofing</h3>
                    <p>
                      We are a family owned and locally operated metal roofing
                      business.
                    </p>
                    <p>
                      We pride ourselves on delivering friendly service with a
                      fast turnaround time, no matter how big or small the job
                      at hand might be. We have an ongoing commitment to
                      delivering customer satisfaction by using the latest
                      technologies and practices.
                    </p>{' '}
                    <p>
                      Whether it's about a new roof installation or a complete
                      re-roof, we’re always here to help.
                    </p>
                    {/* <a
                      href='services'
                      className='ht-btn--default ht-btn--default--dark-hover section-space--top--20'
                    >
                      Our Services
                    </a> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Feature Icon */}

          {/*About section start*/}
          <div className='about-section section-space--inner--120'>
            <div className='container'>
              <div className='about-wrapper row'>
                <div className='col-sm-6 col-12 order-1 order-lg-2'>
                  <div className='about-image about-image-1'>
                    <img src='assets/img/projects/KONE1824-HDR.jpg' alt='' />
                  </div>
                </div>
                <div className='col-sm-6 col-12 order-2 order-lg-3'>
                  <div className='about-image about-image-2'>
                    <img src='assets/img/projects/KONE1864-HDR.jpg' alt='' />
                  </div>
                </div>
                <div className='col-lg-6 col-12 order-3 order-lg-1'>
                  <div className='about-content about-content-1'>
                    <h1>
                      <span>30</span>Years of Expertise
                    </h1>
                    <p>
                      We can offer an extensive range of roofing opinions, such
                      as brand new Colorbond roofs both in residential and
                      commercial, re-roofs and architectural cladding.
                    </p>
                  </div>
                </div>
                <div className='col-lg-6 col-12 order-4'>
                  <div className='about-content about-content-2'>
                    <p>
                      We have an ongoing commitment to delivering customer
                      satisfaction. Whether you’re looking for new roof
                      installation or a complete re-roof, we’re always here to
                      help.
                    </p>
                    <a
                      href={`${process.env.PUBLIC_URL}/contact-us`}
                      className='ht-btn--default ht-btn--default--dark-hover section-space--top--20'
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*About section end*/}

          {/* Fun fact */}

          {/* Team member */}
          <TeamMemberGrid />

          {/* Testimonial Slider */}

          {/* Brand logo */}
          <BrandLogoSlider background='' />
        </div>

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default About;
