import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Component } from 'react';
import './index.scss';
import HomeOne from './HomeOne';
import About from './pages/About';
import Services from './service/Services';
import ServiceDetailsLeftSidebar from './service/ServiceDetailsLeftSidebar';
import ServiceDetailsLeftSidebar2 from './service/ServiceDetailsLeftSidebar2';
import ServiceDetailsLeftSidebar3 from './service/ServiceDetailsLeftSidebar3';
import ServiceDetailsLeftSidebar4 from './service/ServiceDetailsLeftSidebar4';
import ServiceDetailsRightSidebar from './service/ServiceDetailsRightSidebar';
import Projects from './project/Projects';
import ProjectDetails8 from './project/ProjectDetails8';
import ProjectDetails2 from './project/ProjectDetails2';
import ProjectDetails3 from './project/ProjectDetails3';
import ProjectDetails4 from './project/ProjectDetails4';
import ProjectDetails5 from './project/ProjectDetails5';
import ProjectDetails6 from './project/ProjectDetails6';
import ProjectDetails7 from './project/ProjectDetails7';
import GalleryMain from './project/GalleryMain';
import Quote from './pages/Quote';
import ScrollToTop from './components/ScrollToTop';
import NavBar from './components/NavBar';
import MobileMenu from './components/MobileMenu';

import Contact from './pages/Contact';
import PageNotFound from './pages/404';
import NoMAtch from './pages/404';
import { BrowserRouter, Switch, Route, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Sign from './pages/Sign';
import RiskAssessment from './pages/RiskAssessment';
import Employment from './pages/Employment';

class App extends Component {
  render() {
    return (
      <BrowserRouter basename={'/'}>
        <NavBar />
        <MobileMenu />
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/`}
            component={HomeOne}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/home`}
            component={HomeOne}
          />

          <Route path={`${process.env.PUBLIC_URL}/sign/:id`} component={Sign} />
          <Route
            path={`${process.env.PUBLIC_URL}/riskassessment/:id`}
            component={RiskAssessment}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about-us`}
            component={About}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/services`}
            component={Services}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/architectural-cladding`}
            component={ServiceDetailsLeftSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/commercial`}
            component={ServiceDetailsLeftSidebar2}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/residential`}
            component={ServiceDetailsLeftSidebar3}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/sheet-metal`}
            component={ServiceDetailsLeftSidebar4}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/service-details-right-sidebar`}
            component={ServiceDetailsRightSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/projects`}
            component={Projects}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/bulli`}
            component={ProjectDetails8}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/corrimal`}
            component={ProjectDetails2}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/fairy-meadow`}
            component={ProjectDetails3}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/keiraville`}
            component={ProjectDetails4}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/kiama`}
            component={ProjectDetails5}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/kiama-nsw`}
            component={ProjectDetails6}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/shellharbour`}
            component={ProjectDetails7}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact-us`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/quote`}
            component={Quote}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gallery`}
            component={GalleryMain}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/404`}
            component={PageNotFound}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/employment`}
            component={Employment}
          />

          <Route component={NoMAtch} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
