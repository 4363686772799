import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import { NavLink } from 'react-router-dom';

class ServiceGridSlider extends Component {
  render() {
    const params = {
      slidesPerView: 3,
      loop: true,
      speed: 1000,
      watchSlidesVisibility: true,
      spaceBetween: 30,
      autoplay: {
        delay: 3000
      },
      // Responsive breakpoints
      breakpoints: {
        1499: {
          slidesPerView: 3
        },

        991: {
          slidesPerView: 2
        },

        767: {
          slidesPerView: 1
        },

        575: {
          slidesPerView: 1
        }
      }
    };
    let data = [
      {
        img: 'KONE2251-HDR.jpg',
        iconName: 'zmdi zmdi-pin animated infinite wobble zmdi-hc-fw',
        serviceTitle: 'Architectural Cladding',
        serviceExcerpt:
          'We are experts in Architectural Cladding. We are using Lysaght ZENIT product line. Using latest techniques and practices.',
        serviceUrl: 'architectural-cladding'
      },
      {
        img: 'KONE2233-HDR.jpg',
        iconName: 'zmdi zmdi-pin animated infinite wobble zmdi-hc-fw',
        serviceTitle: 'Commercial Roofing',
        serviceExcerpt:
          'Our commercial roofing experience dates 25 years ago. We can manage any size of project in efficient time frime.',
        serviceUrl: 'commercial'
      },
      {
        img: 'KONE1824-HDR.jpg',
        iconName: 'zmdi zmdi-pin animated infinite wobble zmdi-hc-fw',
        serviceTitle: 'Residential Roofing',
        serviceExcerpt:
          'Tailor-made metal roofing solutions for new metal roofs, re-roofs, roof servicing and repairs. Weatherproof roofing suitable for all sizes and designs of residential building. ',
        serviceUrl: 'residential'
      },
      {
        img: 'KONE2200-HDR.jpg',
        iconName: 'zmdi zmdi-pin animated infinite wobble zmdi-hc-fw',
        serviceTitle: 'Sheet Metal Roofing',
        serviceExcerpt:
          'Specialised in new roof installation and complete re-roofing',
        serviceUrl: 'sheet-metal'
      }
    ];

    let DataList = data.map((val, i) => {
      return (
        <div className='swiper-slide' key={i}>
          <div className='service-grid-item'>
            <div className='service-grid-item__image'>
              <div className='service-grid-item__image-wrapper'>
                {/* <NavLink to={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}> */}
                <img
                  src={`assets/img/projects/${val.img}`}
                  className='img-fluid'
                  alt='Roofing Wollongong and wall cladding'
                />
                {/* </NavLink> */}
              </div>
            </div>
            <div className='service-grid-item__content'>
              <h3 className='title'>
                {/* <NavLink to={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}> */}
                {val.serviceTitle}
                {/* </NavLink> */}
              </h3>
              <p className='subtitle'>{val.serviceExcerpt}</p>
              {/* <NavLink to={`${process.env.PUBLIC_URL}/${val.serviceUrl}`} className="see-more-link">More</NavLink> */}
            </div>
          </div>
        </div>
      );
    });
    return (
      <div>
        {/*====================  service grid slider area ====================*/}
        <div className='service-grid-slider-area section-space--inner--120'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='section-title-area text-center'>
                  <h2 className='section-title section-space--bottom--50'>
                    Our Services <span className='title-icon' />
                  </h2>
                </div>
              </div>
              <div className='col-lg-12'>
                <div className='service-slider'>
                  <Swiper {...params}>{DataList}</Swiper>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of service grid slider area  ====================*/}
      </div>
    );
  }
}

export default ServiceGridSlider;
