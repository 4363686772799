import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
//import { render } from 'react-snapshot';
import React from 'react';
import App from './app';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
    gtmId: 'GTM-M9D4ZK9',
    dataLayerName: 'architectural'
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App/>, document.getElementById('app'));

serviceWorker.register();
