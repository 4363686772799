import React, { Component } from 'react';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import { NavLink, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import TagManager from 'react-gtm-module';
import ContactForm from '../components/ContactForm';
const tagManagerArgs = {
  dataLayer: {
    userId: '001',
    userProject: 'project',
    page: 'contact'
  },
  dataLayerName: 'architectural'
};

class ContactEmployment extends Component {
  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <div>
        <Helmet>
          <title>Contact - Geoff Reynolds Roofing</title>
          <meta
            name='description'
            content='Geoff Reynolds Roofing Pty Ltd provides metal roofing services in the Wollongong area. Visit our website to learn how we can serve you.'
          />
        </Helmet>
        {/* Navigation bar */}

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <center>
          {' '}
          {/* <div className='page-banner text-center'>
            <h1>Contact</h1>
            <ul className='page-breadcrumb'>
              <li>
                <NavLink to='/'>Home</NavLink>
              </li>
              <li>Contact</li>
            </ul>
          </div>{' '} */}
        </center>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  content page content ====================*/}
        <div className='page-wrapper section-space--inner--120'>
          {/*Contact section start*/}
          <div className='conact-section'>
            <div className='container'>
              <div className='row'>
                <div className='contact-information'>
                  <center>
                    <h3>Contact Information</h3>
                    <div id='employmentInfo'>
                      <ul>
                        <li>
                          <span className='icon'>
                            <i className='ion-android-map' />
                          </span>
                          <span className='text'>
                            <span>
                              6/56 Montague Street, North Wollongong NSW 2500
                            </span>
                          </span>
                        </li>
                        <li>
                          <span className='icon'>
                            <i className='ion-ios-telephone-outline' />
                          </span>
                          <span className='text'>
                            <a href='tel:+61 242315040'>02 4231 5040</a>
                          </span>
                        </li>
                        <li>
                          <span className='icon'>
                            <i className='ion-ios-email-outline' />
                          </span>
                          <span className='text'>
                            <a href='mailto:info@geoffreynolds.com.au'>
                              info@geoffreynolds.com.au
                            </a>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </center>
                </div>
              </div>
              <br></br>
              <br></br>

              <div className='row section-space--bottom--50'>
                <div className='col'>
                  <div className='contact-map'>
                    <iframe
                      title='google-map'
                      src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3292.0002583630903!2d150.89301871601933!3d-34.40133935432664!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b1319108e4239c3%3A0xdc11f66efed8d4d5!2sGeoff%20Reynolds%20Roofing!5e0!3m2!1sen!2sau!4v1590491491706!5m2!1sen!2sau'
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Contact section end*/}
        </div>
        {/*====================  End of content page content  ====================*/}

        {/* Footer */}
        {/* <Footer /> */}

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default ContactEmployment;
