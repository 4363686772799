import React, {Component} from 'react';
import PhotoGalleryMain from './components/PhotoGalleryMain';
import BackHome from './components/BackHome';
import {NavLink} from 'react-router-dom';
import { Helmet } from "react-helmet";

class GalleryMain extends Component{

    render(){
        return(
            <div>
            <Helmet>
              <title>Gallery - Geoff Reynolds Roofing</title>
              <meta name="description" content="Geoff Reynolds Roofing Pty Ltd provides metal roofing services in the Wollongong area. Visit our website to learn how we can serve you." />

            </Helmet>
                {/* Navigation bar */}

<PhotoGalleryMain/>

<BackHome/>
                {/* Footer */}

                {/* Mobile Menu */}


            </div>
        )

    }
}


export default GalleryMain;
