import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';

class FeatureIcon extends Component{
    render(){

        return(
            <div>
                {/*Feature section start*/}
                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key="1">
                    <div className="feature">

                    <div className="content">
                      <NavLink className="hero-slider__btn" to={`${process.env.PUBLIC_URL}/contact`}>Contact</NavLink>
                    </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12 section-space--bottom--30" key="2">
                    <div className="feature">

                    <div className="content">
                      <NavLink className="hero-slider__btn" to={`${process.env.PUBLIC_URL}/quote`}>Get a Quote</NavLink>
                    </div>
                    </div>
                </div>
</div>

        )
    }
}

export default FeatureIcon;
